import React from 'react'
import { AppGartnerBanner } from '../../components/AppGartnerBanner'
import { AppGartnerDisclaimer } from '../../components/AppGartnerDisclaimer'
import { AppGartnerGartnerNewsSection } from '../../components/AppGartnerNewsSection'
import { AppGartnerSnapAndFormSection } from '../../components/AppGartnerSnapAndFormSection'
import { AppHomeBookDemo } from '../../components/AppHomeBookDemo'
import { Layout } from '../../components/AppLayout'
// import { GartnerForm } from '../../components/GartnerForm'
import { RDHelmet } from '../../components/RDHelmet'

const gartner = () => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  return (
    <Layout>
      <RDHelmet
        subtitle={'Gartner'}
        contentDescription="Discover Raindrop's AI-powered SaaS platform for smarter spend management, cost savings, and procurement efficiency in Gartner's latest reports."
        contentKeywords="content='Gartner spend management report, AI-powered spend management, Gartner SaaS spend management, enterprise spend management solutions, AI-driven procurement software, Gartner recognized spend management, Raindrop Gartner report, spend management software insights'"
        contentOG="AI Powered Source-to-Pay SaaS Platform"
        contentOgDescription="Discover Raindrop's AI-powered SaaS platform for smarter spend management, cost savings, and procurement efficiency in Gartner's latest reports."
        contentOgUrl="https://raindrop.com/resources/gartner"
        schemaMarkup={schemaMarkup}
      />
      <AppGartnerBanner />
      {/* <GartnerForm /> */}
      <AppGartnerSnapAndFormSection />
      <AppGartnerDisclaimer />
      <AppGartnerGartnerNewsSection />
      <AppHomeBookDemo />
    </Layout>
  )
}

export default gartner
